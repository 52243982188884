<template>
  <div id="consult" class="consult_box">
    <div class="consult">
      <div class="title">咨询服务</div>
      <div class="tips">G港联航为港口数字化转型提供专业咨询</div>
      <div class="consult_info">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="consult_item_top">
            <img class="left" :src="item.img" alt="" />
            <div class="consult_box_title">{{ item.title }}</div>
          </div>
          <div class="right">
            <p v-for="(v, i) in item.content" :key="i">{{ v }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
export default {
  setup() {
    const state = reactive({
      list: [
        {
          img: require("../../../assets/imgs/consult/one.png"),
          title: "顶层设计咨询",
          content: [
            "现状调研及研究",
            "规划顶层设计",
            "定义顶层需求",
            "设计达到目标的路径",
          ],
        },
        {
          img: require("../../../assets/imgs/consult/two.png"),
          title: "细节设计咨询",
          content: [
            "找到各系统技术规格",
            "规划各设备硬件技术规格",
            "为客户准备招标建议书（RFP）与招标报价书（RFQ）等",
            "评估供应商",
          ],
        },
        {
          img: require("../../../assets/imgs/consult/three.png"),
          title: "项目管理",
          content: [
            "规划阶段范围、进度、成本、资源的规划",
            "实施阶段整体监控、监理",
            "质量管理和质量控制",
            "设计达到目标的路径",
          ],
        },
        {
          img: require("../../../assets/imgs/consult/four.png"),
          title: "项目实施",
          content: ["系统开发", "硬件集成", "定义顶级需求", "培训与指导"],
        },
      ],
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>
// ConsultCom

.consult_box {
  width: 100%;
  background: url("../../../assets/imgs/consult/consult_pc.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .consult {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0.4rem;
    .title {
      font-size: 0.24rem;
      color: #35404e;
      text-align: center;
    }
    .tips {
      margin: 0.24rem 0;
      font-size: 0.13rem;
      color: #121212;
      text-align: center;
    }
    .consult_info {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .item {
        box-sizing: border-box;
        padding: 0.2rem 0.5rem;
        width: 50%;
        // display: flex;
        .consult_item_top {
          display: flex;
          img {
            margin-right: 0.2rem;
            width: 0.4rem;
            height: 0.4rem;
            object-fit: contain;
          }
          .consult_box_title {
            font-size: 18px;
            color: #35404e;
          }
        }

        .right {
          margin-left: 0.6rem;
          p {
            margin-bottom: 0.1rem;
            list-style: none;
            font-size: 16px;
            color: #121212;
          }
        }
      }
    }
  }
}
</style>
