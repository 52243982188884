<template>
  <!-- 顶部 -->
  <div id="top" class="top">
    <div class="top_con">
      <img class="logo" :src="logoIcon" alt="" />
      <div class="tabs">
        <div
          class="tab"
          :class="{ active_tab: activeTab == v.value }"
          v-for="(v, i) in tabList"
          :key="i"
          @click="onTabChange(v.value, v.href)"
        >
          {{ v.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs } from "@vue/reactivity";
export default {
  name: "TopCom",
  setup() {
    const state = reactive({
      logoIcon: require("../../../assets/imgs/logo.png"), //logo
      // id 固定不变
      tabList: [
        {
          label: "首页",
          value: 0,
          href: "#banner",
        },
        {
          label: "关于我们",
          value: 1,
          href: "#about",
        },
        {
          label: "解决方案",
          value: 2,
          href: "#solution",
        },
        {
          label: "咨询服务",
          value: 3,
          href: "#consult",
        },
        {
          label: "新闻中心",
          value: 4,
          href: "#news",
        },
        // {
        //   label: "战略合作",
        //   value: 4,
        //   href: "#teamwork",
        // },
        {
          label: "联系我们",
          value: 5,
          href: "#connect",
        },
      ], //tab数组
    });
    const activeTab = ref(0);
    const onTabChange = (val, id) => {
      activeTab.value = val;
      console.log(id);
      let offsetTop = document.querySelector(id).offsetTop;
      //将页面跳转到对应的位置
      document.documentElement.scrollTop = offsetTop - 88;
    };
    return {
      ...toRefs(state),
      activeTab,
      onTabChange,
    };
  },
};
</script>

<style lang="scss" scoped>
#top {
  display: block;
  width: 100%;
  height: 0.88rem;
  position: fixed;
  top: 0;
  z-index: 1000;
}
.top {
  width: 100%;
  height: 0.88rem;
  background: #ffffff;
  opacity: 0.8;
  .top_con {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  .logo {
    width: 1.6rem;
    height: 0.55rem;
    // width: 143px;
    // height: 53px;
    object-fit: fill;
  }
  .tabs {
    height: 0.25rem;
    display: flex;
    font-size: 0.14rem;
    font-weight: 400;
    color: #333333;
    .tab {
      margin-left: 0.24rem;
      cursor: pointer;
      height: 100%;
      color: #666;
    }
    .active_tab {
      border-bottom: 2px solid #666666;
    }
  }
}
</style>
