<template>
  <van-swipe id="banner" class="my-swipe" indicator-color="white">
    <van-swipe-item>
      <div class="banner_box">
        <!-- 背景图 -->
        <img class="banner_bg" :src="bannerBg" alt="" />
        <!-- 内容 -->
        <div class="con">
          <!-- 中心点 图标 -->
          <div class="center_box">
            <img class="center_logo scale_tar" :src="centerLogo" alt="" />
          </div>
          <!-- 第一排 小图标 -->
          <div class="box_small w90">
            <img class="banner_small scale_tar" :src="banner1" alt="" />
            <img class="banner_small scale_tar" :src="banner6" alt="" />
          </div>
          <!-- 第二排 中图标 -->
          <div class="box_mid w60">
            <img class="banner_mid scale_tar" :src="banner2" alt="" />
            <img class="banner_mid scale_tar" :src="banner5" alt="" />
          </div>
          <!-- 第三排 大图标 -->
          <div class="box_big w40">
            <img class="banner_big scale_tar" :src="banner3" alt="" />
            <img class="banner_big scale_tar" :src="banner4" alt="" />
          </div>
        </div>
      </div>
    </van-swipe-item>
  </van-swipe>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
export default {
  setup() {
    const state = reactive({
      bannerBg: require("../../../assets/imgs/banner/banner_bg.jpg"), //banner背景
      centerLogo: require("../../../assets/imgs/banner/banner-logo.png"), //banner 中心球
      banner1: require("../../../assets/imgs/banner/banner1.png"), //banner 中六个小球
      banner2: require("../../../assets/imgs/banner/banner2.png"),
      banner3: require("../../../assets/imgs/banner/banner3.png"),
      banner4: require("../../../assets/imgs/banner/banner4.png"),
      banner5: require("../../../assets/imgs/banner/banner5.png"),
      banner6: require("../../../assets/imgs/banner/banner6.png"),
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>
// 轮播
.my-swipe .van-swipe-item {
  color: #fff;
  text-align: center;
  .banner_box {
    height: 6.68rem;
    width: 100%;
    position: relative;
    .banner_bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: fill;
    }
    .con {
      height: 100%;
      position: relative;
      max-width: 12rem;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      .center_box {
        position: absolute;
        transform: translateX(-50%);
        transform-origin: 50% 50%;

        left: 50%;
        top: 1.3rem;
        width: 1.7rem;
        height: 1.7rem;
        object-fit: contain;
      }
      .center_logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .scale_tar {
        transition: all 0.6s;
      }
      .scale_tar:hover {
        transform-origin: center center;
        transform-origin: 50% 50%;
        transform: scale(1.2);
      }
      .box_small,
      .box_mid,
      .box_big {
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
      }
      .box_small {
        margin-top: 1.83rem;
      }
      .box_mid {
        margin-top: 0.73rem;
      }
      .box_big {
        margin-top: 0.53rem;
      }
      .w90 {
        width: 90%;
      }
      .w60 {
        width: 67%;
      }
      .w40 {
        width: 40%;
      }
      .banner_small {
        width: 0.7rem;
        height: 0.7rem;
      }
      .banner_mid {
        width: 0.8rem;
        height: 0.8rem;
      }
      .banner_big {
        width: 0.9rem;
        height: 0.9rem;
      }
    }
  }
}
</style>
