<template>
  <div class="sol_pop" v-show="showPop">
    <div class="sol_info max_w min_w margin_auto">
      <div class="sol_info_t">
        <div class="title">{{ solInfo.title }}</div>
        <img class="close" :src="closeIcon" alt="" @click="onSolPopClose" />
      </div>
      <div class="sol_info_c" v-html="solInfo.moreText"></div>
      <div class="sol_info_b">
        <div class="pre_btn" @click="onChangeSolInfo(0, solInfo.value)">
          上一篇
        </div>
        <div class="next_btn" @click="onChangeSolInfo(1, solInfo.value)">
          下一篇
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
export default {
  props: {
    showPop: {
      type: Boolean,
      default: false,
    },
    solInfo: {
      type: Object,
      default: () => {
        return {
          title: "",
          enTitle: "",
          text: "",
          moreText: "",
          value: 0,
        };
      },
    },
  },
  emits: ["handle-close", "handle-change-info"],
  setup(props, { emit }) {
    const state = reactive({
      closeIcon: require("../../../assets/imgs/solution/close.png"),
    });
    // 关闭弹框
    const onSolPopClose = () => {
      emit("handle-close");
    };
    const onChangeSolInfo = (type, idx) => {
      const payload = {
        type,
        idx,
      };
      emit("handle-change-info", payload);
    };

    return {
      ...toRefs(state),
      onSolPopClose,
      onChangeSolInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.sol_pop {
  z-index: 9998;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #000000;
  opacity: 0.8;
  top: 0;
  left: 0;
  .sol_info {
    width: 90%;
    height: 100%;
    box-sizing: border-box;
    padding: 1rem 0;
    overflow: hidden;
    margin: 0 auto;
    color: #ffffff;
    font-size: 0.14rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .sol_info_t {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 0.36rem;
      .title {
        height: 0.36rem;
        line-height: 0.36rem;
        font-size: 0.36rem;
      }
      .close {
        cursor: pointer;
        width: 0.3rem;
        height: 0.3rem;
        object-fit: fill;
      }
    }
    .sol_info_c {
      overflow: auto;
      margin-top: 0.6rem;
      margin-bottom: 0.3rem;
      flex: 1;
      line-height: 24px;
    }
    .sol_info_b {
      height: 0.24rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .pre_btn,
      .next_btn {
        cursor: pointer;
        font-size: 0.14rem;
        height: 100%;
        width: 0.7rem;
        border: 0.02rem solid #ffffff;
        border-radius: 12px;
        text-align: center;
        line-height: 0.24rem;
      }
    }
  }
}
</style>
