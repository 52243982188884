<template>
  <div id="teamwork" class="teamwork">
    <img class="teamwork_bg" :src="teamworkBgImg" alt="" />
    <div class="teamwork_con max_w min_w margin_auto">
      <div class="text_top">cooperation</div>
      <div class="text_b">指导合作</div>
      <div class="friend_box">
        <div
          class="friend"
          v-for="(v, i) in friendList"
          :key="i"
          @mouseenter="onShowFriend(i + 1)"
          @mouseleave="onHideFriend"
        >
          <img class="def_friend" :src="v.defImg" alt="" />
          <img
            v-show="actFriend == i + 1"
            class="hidden_friend"
            :src="v.actImg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from "vue";
export default {
  setup() {
    // 合作相关
    const state = reactive({
      teamworkBgImg: require("../../../assets/imgs/teamwork/teamwork_bg.png"), //关于我们 右侧文字img
      friendList: [
        {
          defImg: require("../../../assets/imgs/teamwork/def1.png"),
          actImg: require("../../../assets/imgs/teamwork/hover1.png"),
        },
        {
          defImg: require("../../../assets/imgs/teamwork/def2.png"),
          actImg: require("../../../assets/imgs/teamwork/hover2.png"),
        },
        {
          defImg: require("../../../assets/imgs/teamwork/def3.png"),
          actImg: require("../../../assets/imgs/teamwork/hover3.png"),
        },
        {
          defImg: require("../../../assets/imgs/teamwork/def4.png"),
          actImg: require("../../../assets/imgs/teamwork/hover4.png"),
        },
        {
          defImg: require("../../../assets/imgs/teamwork/def5.png"),
          actImg: require("../../../assets/imgs/teamwork/hover5.png"),
        },
        {
          defImg: require("../../../assets/imgs/teamwork/def6.png"),
          actImg: require("../../../assets/imgs/teamwork/hover6.png"),
        },
        {
          defImg: require("../../../assets/imgs/teamwork/def7.png"),
          actImg: require("../../../assets/imgs/teamwork/hover7.png"),
        },
        {
          defImg: require("../../../assets/imgs/teamwork/def8.png"),
          actImg: require("../../../assets/imgs/teamwork/hover8.png"),
        },
      ], //合作伙伴
    });
    const actFriend = ref(0);
    const onShowFriend = (idx) => {
      // alert(1);
      actFriend.value = idx;
    };
    const onHideFriend = () => {
      actFriend.value = 0;
    };

    return {
      ...toRefs(state),
      actFriend,
      onShowFriend,
      onHideFriend,
    };
  },
};
</script>

<style lang="scss" scoped>
// teamwork
.teamwork {
  display: none;
  position: relative;
  min-height: 6.6rem;
  width: 100%;
  .teamwork_bg {
    width: 100%;
    height: 100%;
    object-fit: fill;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .teamwork_con {
    width: 100%;
    height: 100%;
    padding-top: 1.1rem;
    padding-bottom: 1.19rem;
    .text_top {
      height: 0.5rem;
      font-size: 0.48rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
      opacity: 0.2;
    }
    .text_b {
      margin-bottom: 0.04rem;
      height: 0.3rem;
      font-size: 30px;
      font-weight: bold;
      color: #999999;
      line-height: 0.3rem;
    }

    .friend_box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .friend {
        cursor: pointer;
        position: relative;
        margin-top: 0.52rem;
      }
      .hidden_friend {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
</style>
