<template>
  <div id="about" class="about">
    <div class="max_w min_w margin_auto about_con">
      <div class="about_l">
        <div class="title to-top">港联航科技（深圳）有限公司</div>
        <div class="text to-top">
          港联航科技作为港航智能化全栈解决方案供应商，致力于为港口码头、航运物流、陆港、铁路场站以及国家物流枢纽提供一站式信息化解决方案，同时为用户提供基于云计算、大数据、移动互联网、物联网等技术的智能化SaaS综合服务。
          目前，公司汇聚数十位产学研专家学者及实践经验丰富的技术团队，可为企业在智慧港口及多式联运建设中，提供“专家”级项目总体规划和成熟可靠的技术解决方案。在规划专业性、技术先进性、新需求响应速度、用户体验等方面具有明显特色优势。
          以人工智能、大数据、云计算、区块链为代表的新技术日趋成熟,相关应用处在快速攀升期,港联航科技将以此为契机，建设基于智能感知、智能分析、智能计算、智能管理、智能决策的港航生态圈，助力客户快速实现数字化转型
        </div>
      </div>
      <img class="about_r to-top" :src="aboutTextImg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    const aboutTextImg = require("../../../assets/imgs/about/about_text.png"); //关于我们 右侧文字img
    return {
      aboutTextImg,
    };
  },
};
</script>

<style lang="scss" scoped>
// about us
.about {
  min-height: 5.35rem;
  .about_con {
    overflow: hidden;
    position: relative;
    background: url("../../../assets/imgs/about/about_bg.png") center center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.2rem;
    padding-top: 0.9rem;
    .about_l {
      min-width: 8rem;
      max-width: 10rem;
      .title {
        width: fit-content;
        margin-bottom: 0.3rem;
        height: 0.75rem;
        font-size: 0.3rem;
        font-weight: bold;
        color: #666666;
        border-bottom: 1px solid #999999;
      }
      .text {
        font-size: 0.16rem;
        font-weight: 400;
        color: #666666;
        line-height: 0.36rem;
      }
    }
    .about_r {
      width: 0.85rem;
      height: 3rem;
      object-fit: contain;
    }
  }
}
</style>
