<template>
  <div id="news" class="news">
    <img :src="newsImgBg" alt="" class="news_bg" />
    <div class="news_con max_w min_w margin_auto">
      <div class="title mt120 mb100">
        <div class="title_t mb10">new center</div>
        <div class="title_b">新闻中心</div>
      </div>
      <van-swipe
        class="news_swipe"
        indicator-color="white"
        :stop-propagation="false"
      >
        <!-- pc 展示 -->
        <van-swipe-item v-for="(v, i) in newsListPc" :key="i">
          <div class="news_banner">
            <div
              class="news_item"
              v-for="(el, idx) in v.children"
              :key="idx"
              @mouseenter="onShowNews(el.index)"
              @mouseleave="onHideNews"
            >
              <!-- 默认展示 -->
              <div
                class="def_item"
                :class="{ hidden_item: actNews == el.index }"
                @click="onPopOpen(el.index - 1)"
              >
                <div class="news_item_t">
                  {{ el.date }}
                </div>
                <div class="tips mt20">{{ el.title }}</div>
                <div class="news_item_con mt120">
                  {{ el.introduce }}
                </div>
              </div>
              <!-- hover效果 -->
              <div
                class="active_item hidden_item"
                :class="{ show_item: actNews == el.index }"
                @click="onPopOpen(el.index - 1)"
              >
                <div class="tips">{{ el.title }}</div>
                <img :src="el.img" alt="" />
                <div class="news_item_con mb24">
                  {{ el.introduce }}
                </div>
                <div class="new_item_b">
                  {{ el.date }}
                </div>
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
      <van-swipe class="m_news_swipe" indicator-color="white">
        <van-swipe-item v-for="(v, i) in newsListM" :key="i">
          <div class="news_banner">
            <div
              class="news_item"
              @mouseenter="onShowNews(v.index)"
              @mouseleave="onHideNews"
              @click="onPopOpen(i)"
            >
              <!-- 默认展示 -->
              <div
                class="def_item"
                :class="{ hidden_item: actNews == v.index }"
              >
                <div class="news_item_t">
                  {{ v.date }}
                </div>
                <div class="tips mt20">{{ v.title }}</div>
                <div class="news_item_con mt120">
                  {{ v.introduce }}
                </div>
              </div>
              <!-- hover效果 -->
              <div
                class="active_item hidden_item"
                :class="{ show_item: actNews == v.index }"
              >
                <div class="tips">{{ v.title }}</div>
                <img class="news_hover_img" :src="v.img" alt="" />
                <div class="news_item_con mb24">
                  {{ v.introduce }}
                </div>
                <div class="new_item_b">
                  {{ v.date }}
                </div>
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
  <news-pop
    v-show="showPopBool"
    :showPop="showPopBool"
    :info-list="info"
    @handle-close="onPopClose"
    @handle-change-info="onChangeInfo"
  ></news-pop>
</template>

<script>
import { ref, reactive, toRefs } from "vue";
import NewsPop from "./NewsPop.vue";

export default {
  components: {
    NewsPop,
  },
  setup() {
    const state = reactive({
      // 新闻背景
      newsImgBg: require("../../../assets/imgs/news/news_bg.png"),
      // 新闻消息
      newsListPc: [
        {
          index: 1,
          children: [
            {
              index: 1,
              img: require("../../../assets/imgs/news/show.png"),
              date: "2022-09-04",
              title: "2022绿色与安全港口大会在长沙召开，Glink港联航稳居展示C位",
              introduce: "交通运输部发布，以下文章来源于港口科技",
            },
            {
              index: 2,
              img: require("../../../assets/imgs/news/show.png"),
              date: "2022-08-02",
              title:
                "交通运输部办公厅关于做好2023年度交通运输标准预算项目申报工作的通知",
              introduce: "交通运输部发布，以下文章来源于港口科技",
            },
            {
              index: 3,
              img: require("../../../assets/imgs/news/show.png"),
              date: "2022-07-14",
              title: "港口经营人交付货物义务之释明",
              introduce:
                "由于法律规定不够完善，港口经营人合法合规意识淡薄，对于不合法交付货物可能产生的风险认识不足，实践中时常产生港口经营人是否合法交付货物的争议，给港口经营人带来由于不合法交付货物的经济赔偿责任风险。",
            },
          ],
        },
        {
          index: 2,
          children: [
            {
              index: 4,
              img: require("../../../assets/imgs/news/show.png"),
              date: "2022-05-30",
              title: "港联航科技(深圳)有限公司正式进军港口市场",
              introduce:
                "港口数字化建设将为港口企业实现全方位赋能，让未来港口更加智能、高效、绿色、安全",
            },
            {
              index: 5,
              img: require("../../../assets/imgs/news/show.png"),
              date: "2022-05-17",
              title: "关于“推进港口集装箱铁水联运高质量发展”视频研讨的通知",
              introduce: "中国港口协会发布，以下文章来源于港口科技",
            },
            {
              index: 6,
              img: require("../../../assets/imgs/news/show.png"),
              date: "2022-03-14",
              title:
                "交通运输部关于印发《港口及其一线人员新冠肺炎疫情防控工作指南（第十版）》的通知",
              introduce: "交通运输部发布，以下文章来源于港口科技",
            },
          ],
        },
        {
          index: 2,
          children: [
            {
              index: 7,
              img: require("../../../assets/imgs/news/show.png"),
              date: "2022-02-20",
              title: "数字化劳动力赋能智慧港口",
              introduce:
                "在今年“两会”上，数字化技术推动发展的议题被高频讨论，“数字化劳动力”首次亮相“两会”，广受关注。",
            },
            {
              index: 8,
              img: require("../../../assets/imgs/news/show.png"),
              date: "2022-07-14",
              title:
                "智慧港口 | 关于发布《智慧港口等级评价指南 集装箱码头评价实施细则》（试行）的通知",
              introduce: "中国港口协会发布，以下文章来源于港口科技",
            },
            {
              index: 9,
              img: require("../../../assets/imgs/news/show.png"),
              date: "2022-07-01",
              title:
                "交通运输部办公厅关于印发《基于区块链的进口干散货进出港业务电子平台建设指南》的通知",
              introduce:
                "辽宁、河北、天津、山东、江苏、上海、浙江、安徽、福建、广东、广西、海南、重庆、湖南、湖北、江西省（区、市）交通运输厅（局、委），中国船东协会、中国港口协会，部规划研究院、部科学研究院、部水运科学研究院、中国交通通信信息中心",
            },
          ],
        },

        //辽宁、河北、天津、山东、江苏、上海、浙江、安徽、福建、广东、广西、海南、重庆、湖南、湖北、江西省（区、市）交通运输厅（局、委），中国船东协会、中国港口协会，部规划研究院、部科学研究院、部水运科学研究院、中国交通通信信息中心
      ],
      newsListM: [
        {
          index: 1,
          img: require("../../../assets/imgs/news/show.png"),
          date: "2022-09-04",
          title: "2022绿色与安全港口大会在长沙召开，Glink港联航稳居展示C位",
          introduce: "交通运输部发布，以下文章来源于港口科技",
        },
        {
          index: 2,
          img: require("../../../assets/imgs/news/show.png"),
          date: "2022-08-02",
          title:
            "交通运输部办公厅关于做好2023年度交通运输标准预算项目申报工作的通知",
          introduce: "交通运输部发布，以下文章来源于港口科技",
        },
        {
          index: 3,
          img: require("../../../assets/imgs/news/show.png"),
          date: "2022-07-14",
          title: "港口经营人交付货物义务之释明",
          introduce:
            "由于法律规定不够完善，港口经营人合法合规意识淡薄，对于不合法交付货物可能产生的风险认识不足，实践中时常产生港口经营人是否合法交付货物的争议，给港口经营人带来由于不合法交付货物的经济赔偿责任风险。",
        },
        {
          index: 4,
          img: require("../../../assets/imgs/news/show.png"),
          date: "2022-05-30",
          title: "港联航科技(深圳)有限公司正式进军港口市场",
          introduce:
            "港口数字化建设将为港口企业实现全方位赋能，让未来港口更加智能、高效、绿色、安全",
        },
        {
          index: 5,
          img: require("../../../assets/imgs/news/show.png"),
          date: "2022-05-17",
          title: "关于“推进港口集装箱铁水联运高质量发展”视频研讨的通知",
          introduce: "中国港口协会发布，以下文章来源于港口科技",
        },
        {
          index: 6,
          img: require("../../../assets/imgs/news/show.png"),
          date: "2022-03-14",
          title:
            "交通运输部关于印发《港口及其一线人员新冠肺炎疫情防控工作指南（第十版）》的通知",
          introduce: "交通运输部发布，以下文章来源于港口科技",
        },
        {
          index: 7,
          img: require("../../../assets/imgs/news/show.png"),
          date: "2022-02-20",
          title: "数字化劳动力赋能智慧港口",
          introduce:
            "在今年“两会”上，数字化技术推动发展的议题被高频讨论，“数字化劳动力”首次亮相“两会”，广受关注。",
        },
        {
          index: 8,
          img: require("../../../assets/imgs/news/show.png"),
          date: "2022-07-14",
          title:
            "智慧港口 | 关于发布《智慧港口等级评价指南 集装箱码头评价实施细则》（试行）的通知",
          introduce: "中国港口协会发布，以下文章来源于港口科技",
        },
        {
          index: 9,
          img: require("../../../assets/imgs/news/show.png"),
          date: "2022-07-01",
          title:
            "交通运输部办公厅关于印发《基于区块链的进口干散货进出港业务电子平台建设指南》的通知",
          introduce:
            "辽宁、河北、天津、山东、江苏、上海、浙江、安徽、福建、广东、广西、海南、重庆、湖南、湖北、江西省（区、市）交通运输厅（局、委），中国船东协会、中国港口协会，部规划研究院、部科学研究院、部水运科学研究院、中国交通通信信息中心",
        },
      ],
      // 详情
      infoList: [
        [require("../../../assets/imgs/news/nine_0.jpeg")],
        [
          require("../../../assets/imgs/news/eight_0.png"),
          require("../../../assets/imgs/news/eight_1.png"),
        ],
        [
          require("../../../assets/imgs/news/one_0.png"),
          require("../../../assets/imgs/news/one_1.png"),
          require("../../../assets/imgs/news/one_2.png"),
          require("../../../assets/imgs/news/one_3.png"),
          require("../../../assets/imgs/news/one_4.png"),
          require("../../../assets/imgs/news/one_5.png"),
          require("../../../assets/imgs/news/one_6.png"),
          require("../../../assets/imgs/news/one_7.png"),
          require("../../../assets/imgs/news/one_8.png"),
          require("../../../assets/imgs/news/one_9.png"),
        ],
        [require("../../../assets/imgs/news/two_0.png")],
        [
          require("../../../assets/imgs/news/three_0.png"),
          require("../../../assets/imgs/news/three_1.png"),
          require("../../../assets/imgs/news/three_2.png"),
          require("../../../assets/imgs/news/three_3.png"),
          require("../../../assets/imgs/news/three_4.png"),
          require("../../../assets/imgs/news/three_5.png"),
        ],
        [require("../../../assets/imgs/news/four_0.png")],
        [
          require("../../../assets/imgs/news/five_0.png"),
          require("../../../assets/imgs/news/five_1.png"),
          require("../../../assets/imgs/news/five_2.png"),
          require("../../../assets/imgs/news/five_3.png"),
          require("../../../assets/imgs/news/five_4.png"),
          require("../../../assets/imgs/news/five_5.png"),
        ],
        [
          require("../../../assets/imgs/news/six_0.png"),
          require("../../../assets/imgs/news/six_1.png"),
        ],
        [require("../../../assets/imgs/news/seven_0.png")],
      ],
    });
    const actNews = ref(0);
    const onShowNews = (idx) => {
      actNews.value = idx;
    };
    const onHideNews = () => {
      actNews.value = 0;
    };
    /**
     * 详情相关数据
     */
    const showPopBool = ref(false);
    // const solInfo = ref(state.solutionList[0]);
    // view more
    const info = ref(null);
    const activeIndex = ref(0);
    const onPopOpen = (index) => {
      showPopBool.value = true;
      activeIndex.value = index;
      info.value = state.infoList[index];
      console.log(info.value);
    };
    // 关闭弹框
    const onPopClose = () => {
      showPopBool.value = false;
    };
    // sol 详情弹框 上下切换
    const onChangeInfo = ({ type }) => {
      let len = state.infoList.length;
      let index = "";
      if (type) {
        //下一篇
        index = activeIndex.value + 1 > len - 1 ? 0 : activeIndex.value + 1;
      } else {
        index = activeIndex.value - 1 < 0 ? len - 1 : activeIndex.value - 1;
      }
      activeIndex.value = index;
      info.value = state.infoList[index];
      // activeTabSol.value = index;
    };
    return {
      ...toRefs(state),
      actNews,
      onShowNews,
      onHideNews,
      info,
      showPopBool,
      onPopClose,
      onPopOpen,
      onChangeInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
// new center
.news {
  height: 9.42rem;
  position: relative;
  width: 100%;
  .news_bg {
    width: 100%;
    height: 100%;
    object-fit: fill;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .news_con {
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    .title {
      margin-top: 1.2rem;
      margin-bottom: 1.1rem;
      .title_t {
        width: 3.15rem;
        height: 0.4rem;
        font-size: 0.48rem;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.4rem;
        opacity: 0.2;
      }
      .title_b {
        height: 0.3rem;
        font-size: 0.3rem;
        font-weight: bold;
        color: #ffffff;
        line-height: 0.3rem;
      }
    }
    .m_news_swipe {
      display: none;
    }
    .news_banner {
      width: 100%;
      height: 4.9rem;
      display: flex;
      justify-content: space-between;
      .news_item {
        padding: 0.6rem 0.14rem 0 0.14rem;
        max-width: 33%;
        min-width: 30%;
        // height: 4.9rem;
        border: 2px solid #999999;
        .active_item {
          display: none;
        }
        .hidden_item {
          display: none;
        }
        .show_item {
          display: block;
        }
        .news_item_t {
          font-size: 0.6rem;
          font-weight: bold;
          color: #ffffff;
          line-height: 0.55rem;
        }
        .tips {
          font-size: 0.18rem;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.36rem;
        }
        .news_item_con {
          font-size: 0.14rem;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.28rem;
        }
        .new_item_b {
          height: 0.13rem;
          font-size: 0.14rem;
          font-weight: bold;
          color: #ffffff;
          line-height: 0.13rem;
        }
      }
    }
  }
}
</style>
