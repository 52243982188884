<template>
  <div id="connect" class="connect border_box">
    <div class="connect_con max_w margin_auto border_box">
      <div class="text_top mt60">contact us</div>
      <div class="text_b">联系我们</div>
      <div class="connect_con_view mt28 mb24">
        <div class="connect_l">
          <div class="company mb24">港联航科技（深圳）有限公司</div>
          <div class="tel other mt20">联系电话：400-886-8221</div>
          <div class="email other mt20">联系邮箱：info@g-linkcloud.com</div>
          <div class="ads other mt20">
            地址：深圳市南山区桃园东路西海明珠大厦16层.
          </div>
          <div class="net other mt20 mb24">网址：www.d2dbay.com</div>
          <div class="connect_type">
            <img
              v-for="(v, i) in connectTypeList"
              @mouseenter="onShowCanect"
              @mouseleave="onHideCanect"
              :key="i"
              :src="v.img"
              alt=""
              class="qq mr18"
            />
          </div>
        </div>
        <div class="connect_r">
          <div class="line_one mb15">
            <div class="item mb15">
              <div class="label mr15">姓名</div>
              <input type="text" />
            </div>
            <div class="item">
              <div class="label mr15">电话</div>
              <input type="text" />
            </div>
            <div class="item">
              <div class="label mr15">邮箱</div>
              <input type="text" />
            </div>
            <div class="item">
              <div class="label mr15">单位</div>
              <input type="text" />
            </div>
          </div>
          <div class="item_con mb15">
            <div class="label mr15">内容</div>
            <input type="text" class="input_con" />
          </div>
          <div class="submit">提交</div>
        </div>
      </div>
      <img v-show="showCodeBool" class="code_pop" :src="codeImg" alt="" />
      <div class="statement">
        Copyright © 2022 , 港联航科技（深圳）有限公司 |

        <a
          href="https://beian.miit.gov.cn"
          target="_blank"
          class="record-num pl-10"
          >粤ICP备2022021915号-1</a
        >
        <span class="pr-10">版权所有</span>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from "vue";
export default {
  setup() {
    const state = reactive({
      // 联系方式图片
      connectTypeList: [
        {
          img: require("../../../assets/imgs/bottom/qq.png"),
        },
        {
          img: require("../../../assets/imgs/bottom/wechat.png"),
        },
        {
          img: require("../../../assets/imgs/bottom/weibo.png"),
        },
      ],
      codeImg: require("../../../assets/imgs/bottom/qrcode.jpeg"),
    });
    const showCodeBool = ref(false);
    // 显示联系二维码
    const onShowCanect = () => {
      showCodeBool.value = true;
    };
    const onHideCanect = () => {
      showCodeBool.value = false;
    };

    return {
      ...toRefs(state),
      showCodeBool,
      onShowCanect,
      onHideCanect,
    };
  },
};
</script>

<style lang="scss" scoped>
.connect {
  overflow: hidden;
  position: relative;
  min-height: 3.8rem;
  width: 100%;
  background: #0c2042;
  .connect_con {
    overflow: hidden;
    min-width: 9rem;
    .text_top {
      height: 0.5rem;
      font-size: 0.48rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.4rem;
      opacity: 0.2;
    }
    .text_b {
      margin-bottom: 0.04rem;
      height: 0.3rem;
      font-size: 30px;
      font-weight: bold;
      color: #ffffff;
      line-height: 0.3rem;
    }
    .connect_con_view {
      display: flex;
      min-width: 6rem;
      .connect_l {
        width: 40%;
        .company {
          height: 0.16rem;
          font-size: 0.16rem;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.16rem;
        }
        .other {
          height: 0.12rem;
          font-size: 0.12rem;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.12rem;
        }
        .connect_type {
          position: relative;
          display: flex;

          .qq,
          .wechat,
          .weibo {
            cursor: pointer;
            width: 0.17rem;
            height: 0.14rem;
          }
        }
      }
      .connect_r {
        min-width: 50%;
        flex: 1;
        .line_one {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .item {
            height: 0.3rem;
            min-width: 30%;
            max-width: 30%;
            display: flex;
            font-size: 0.12rem;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.3rem;
            border-bottom: 1px solid #ffffff;
            .label,
            input {
              height: 100%;
            }
          }
        }
        .item_con {
          min-width: 60%;
          max-width: 100%;
          font-size: 0.12rem;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.3rem;
          .label,
          input {
            width: 100%;
            height: 100%;
          }
          input {
            height: 100%;
            border-bottom: 1px solid #ffffff;
          }
          input:focus {
            border-bottom: 1px solid #ffffff;
          }
          .input_con {
            height: 0.3rem;
            line-height: 0.3rem;
          }
        }
        .submit {
          width: 0.78rem;
          height: 0.22rem;
          border: 1px solid #ffffff;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.22rem;
          font-size: 0.12rem;
          text-align: center;
        }
      }
      input {
        border: none;
        background-color: inherit;
        font-size: 0.12rem;
        font-weight: 400;
        color: #ffffff;
      }
      input:focus {
        background-color: inherit;
        border: none;
        font-size: 0.12rem;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .code_pop {
      position: absolute;
      top: 50px;
    }
    .statement {
      text-align: center;
      // height: 0.4rem;
      font-size: 0.14rem;
      color: #ffffff;
      line-height: 0.4rem;
      a {
        color: rgba(255, 255, 255, 0.8);
        text-decoration: underline;
      }
      span {
        padding-left: 10px;
      }
    }
  }
}
</style>
