import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import "./assets/css/index.css";
import "./assets/css/reset.css"; //样式重置文件
import "./assets/css/animation.css"; //简单动画统一封装
import "./assets/css/common.css"; //global样式
import "./assets/css/media.scss"; //media样式
import "@vant/touch-emulator";
// 动态计算根字体大小
const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName("html")[0];
  let htmlWidth =
    document.documentElement.clientWidth || document.body.clientWidth;
  let designWidth = 0;
  if (htmlWidth >= 1200) {
    htmlWidth = 1200;
    designWidth = 1200;
  }
  if (htmlWidth < 1200 && htmlWidth > 750) {
    htmlWidth = 750;
    designWidth = 750;
  }
  if (htmlWidth <= 750 && htmlWidth > 320) {
    htmlWidth = 750;
    designWidth = 750;
  }
  if (htmlWidth <= 320) {
    htmlWidth = 320;
    designWidth = 750;
  }

  //与淘宝做法不同，直接采用简单的rem换算方法1rem=100px
  // htmlWidth * 100 / designWidth;
  // htmlDom.style.fontSize = `${htmlWidth / 12}px`;
  console.log("====");
  console.log(htmlWidth);
  console.log(designWidth);
  console.log(`${(htmlWidth * 100) / designWidth}`);
  htmlDom.style.fontSize = `${(htmlWidth * 100) / designWidth}px`;
};
// window.webbsdk = websdk;
window.onresize = setHtmlFontSize;
setHtmlFontSize();
//引入vant-ui
import { Swipe, SwipeItem } from "vant";
import "vant/lib/index.css";
let app = createApp(App);
app.use(Swipe).use(SwipeItem);
app.use(store).use(router).mount("#app");
