<template>
  <div
    class="sol_info max_w min_w margin_auto"
    v-show="showPop"
    @click.self="onSolPopClose"
  >
    <div class="sol_info_t" @click="onSolPopClose">
      <!-- <div class="title">{{ solInfo.title }}</div> -->
      <img class="close" :src="closeIcon" alt="" @click="onSolPopClose" />
    </div>
    <div class="sol_info_c" @click="onSolPopClose">
      <img :src="item" alt="" v-for="(item, i) in infoList" :key="i" />
    </div>

    <div class="sol_info_b">
      <div class="pre_btn" @click="onChangeSolInfo(0)">上一篇</div>
      <div class="next_btn" @click="onChangeSolInfo(1)">下一篇</div>
    </div>
  </div>
  <div class="sol_pop" v-show="showPop" @click.stop="onSolPopClose"></div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
export default {
  props: {
    showPop: {
      type: Boolean,
      default: false,
    },
    infoList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["handle-close", "handle-change-info"],
  setup(props, { emit }) {
    const state = reactive({
      closeIcon: require("../../../assets/imgs/solution/close.png"),
    });
    // 关闭弹框
    const onSolPopClose = () => {
      emit("handle-close");
    };
    const onChangeSolInfo = (type, idx) => {
      const payload = {
        type,
        idx,
      };
      emit("handle-change-info", payload);
    };

    return {
      ...toRefs(state),
      onSolPopClose,
      onChangeSolInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.sol_pop {
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #000000;
  opacity: 0.8;
  top: 0;
  left: 0;
}
.sol_info {
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 80%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.8rem 0;
  overflow: hidden;
  margin: 0 auto;
  color: #ffffff;
  font-size: 0.14rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // overflow: scroll;
  .sol_info_t {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0.36rem;
    .title {
      height: 0.36rem;
      line-height: 0.36rem;
      font-size: 0.36rem;
    }
    .close {
      cursor: pointer;
      width: 0.3rem;
      height: 0.3rem;
      object-fit: fill;
    }
  }
  .sol_info_c {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    flex: 1;
    width: 100%;
    line-height: 24px;
    overflow: auto;
    background: #ffffff;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .sol_info_b {
    height: 0.24rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .pre_btn,
    .next_btn {
      cursor: pointer;
      font-size: 0.14rem;
      height: 100%;
      width: 0.7rem;
      border: 0.02rem solid #ffffff;
      border-radius: 12px;
      text-align: center;
      line-height: 0.24rem;
    }
  }
}
</style>
